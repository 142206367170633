// Mixins specific to the SVG sprite usage
@mixin svg-icon {
  fill: $color-off-black;
  display: inline-block;
  width: 14px;
  height: 14px;
  color: $color-off-black;
  @include breakpoint($portrait-up) {
    width: 35px;
    height: 35px;
  }
}

@mixin svg-icon-inline-mask($filename) {
  -webkit-mask: inline-image('icons/src/#{$filename}.svg');
  mask: inline-image('icons/src/#{$filename}.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
}
